import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Elements

// Home Pages
import Index from "./pages/home/index";

import Sitemap from "./pages/sitemap";

// About Pages
import AboutUs2 from "./pages/about/about";

// Contact Pages
import Contact1 from "./pages/contact/contact";

// Error Pages
import Error404 from "./pages/error/error-404";

// Shop Pages

// Portfolio Pages

import Website from "./pages/portfolio/website";
import Uiux from "./pages/portfolio/uiux";
import Branding from "./pages/portfolio/branding";

import Work from "./pages/portfolio/work";
import Diet from "./pages/portfolio/diet";
import Photon from "./pages/portfolio/photon";
import Maxcess from "./pages/portfolio/maxcess";
import Nutritive from "./pages/portfolio/nutritive";
import Karwaan from "./pages/portfolio/karwaan";
import Igi from "./pages/portfolio/igi";
import Ijcpms from "./pages/portfolio/ijcpms";
import Beveracious from "./pages/portfolio/beveracious";
import Hotbrews from "./pages/portfolio/hotbrews";
import Ador from "./pages/portfolio/ador";
import Advent from "./pages/portfolio/advent";
import Webook from "./pages/portfolio/webook";

// Blog Pages

import Privacy from "./pages/blog/privacy";
import Refund from "./pages/blog/refund";
import TermsConditions from "./pages/blog/terms-and-conditions";

const Markup = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Home Pages */}
          <Route path="/" exact element={<Index/>} />

          <Route path="/sitemap" exact element={<Sitemap/>} />

          {/* About Pages */}

          <Route path="/about" exact element={<AboutUs2/>} />

    

          {/* Contact Pages */}
          <Route path="/contact" exact element={<Contact1/>} />

          {/* Error Pages */}
          <Route path="/error-404" exact element={<Error404/>} />

          <Route path="/website" exact element={<Website/>} />

          <Route path="/uiux" exact element={<Uiux/>} />
          <Route path="/work" exact element={<Work/>} />
          <Route path="/branding" exact element={<Branding/>} />
          <Route path="/diet" exact element={<Diet/>} />
          <Route path="/photon" exact element={<Photon/>} />
          <Route path="/social-innovation" exact element={<socialInnovation/>} />
          <Route path="/maxcess" exact element={<Maxcess/>} />
          <Route path="/nutritive" exact element={<Nutritive/>} />
          <Route path="/presume-trade" exact element={<presumeTrade/>} />
          <Route path="/karwaan" exact element={<Karwaan/>} />
          <Route path="/igi" exact element={<Igi/>} />
          <Route path="/ijcpms" exact element={<Ijcpms/>} />
          <Route path="/beveracious" exact element={<Beveracious/>} />
          <Route path="/hotbrews" exact element={<Hotbrews/>} />
          <Route path="/ador" exact element={<Ador/>} />
          <Route path="/advent" exact element={<Advent/>} />
          <Route path="/webook" exact element={<Webook/>} />

          {/* Blog Pages */}

          <Route path="/privacy" exact element={<Privacy />} />
          <Route path="/terms-and-conditions" exact element={<TermsConditions />} />
          <Route path="/refund" exact element={<Refund />} />
        </Routes>
      </BrowserRouter>

      {/* <BackToTop /> */}
    </>
  );
};

export default Markup;
