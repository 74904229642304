import React, { Component } from 'react';
import {Link } from 'react-router-dom';


class PortSingle3 extends Component {
	
	render(){
		return (
			<>
					
				<div className="page-content bg-white">

					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-white" >
							<div className="container">

                            <div className="heading-bx text-center">
								<h3 className="title-head m-b0">Sitemap</h3> <hr />
							</div>

							<div className="row">
								<div className="col-6 col-lg-3 col-md-3 col-sm-6">
								<div className="widget footer_widget">
									    <h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}><Link to={"work"}>Work</Link></h5>
										<ul style={ {textDecorationLine: 'Underline'}} >
											<li ><Link to={"webook"}>Webook</Link></li>
											<li><Link to={"advent"}>Advent Technologies</Link></li>
											<li><Link to={"siet"}>Dnyanshree Institute of Engineering & Technology</Link></li>
											<li><Link to={"maxcess"}>Maxcess</Link></li>
											<li><Link to={"nutritive"}>Nutritive</Link></li>
											<li><Link to={"presume"}>Presume</Link></li>
											<li><Link to={"social-innovation"}>Social Innovation LLC.</Link></li>
											<li><Link to={"ador"}>ADOR Welding Ltd.</Link></li>
											<li><Link to={"photon"}>Photon Eye Center</Link></li>
											<li><Link to={"beveracious"}>Beveracious</Link></li>
											<li><Link to={"ijcpms"}>IJCPMS</Link></li>
											<li><Link to={"hotbrews"}>Hot Brews</Link></li>
											<li><Link to={"karwaan"}>Karwaan Foundation</Link></li>
											<li><Link to={"igi"}>Indira Group of Institutes</Link></li>
											<li><Link to={"shilpa-kankani"}>Shilpa Kankani</Link></li>
											
										</ul>
									</div>
								</div>
								<div className="col-6 col-lg-3 col-md-3 col-sm-6">
									<div className="widget footer_widget">
									    <h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}>  Services</h5>
										<ul style={ {textDecorationLine: 'Underline'}}>
											<li><Link to={"branding"}>Branding</Link></li>
											<li><Link to={"website"}>Web Design</Link></li>
											<li><Link to={"uiux"}>UI/UX Design</Link></li>
											
										</ul>
									</div>
								</div>
								<div className="col-6 col-lg-3 col-md-2 col-sm-6">
									<div className="widget footer_widget">
										<h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}><Link to={"about"}>About</Link></h5>
									  	<ul style={ {textDecorationLine: 'Underline'}}>
											<li><Link to={"about"}>Vision</Link></li>
											<li><Link to={"about"}>Mission</Link></li>
											<li><Link to={"about"}>Values</Link></li>
											<li><Link to={"about"}>Who we are</Link></li>
											<li><Link to={"about"}>Clientele</Link></li>
										</ul>
									</div>
								</div>
								<div className="col-6 col-lg-3 col-md-5 col-sm-6 footer-col-4">
									<div className="widget widget_gallery gallery-grid-4">
										<h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}><Link to={"careers"}>Careers</Link></h5>
									
									</div>
								</div>

								<div className="col-6 col-lg-3 col-md-5 col-sm-6 footer-col-4">
									<div className="widget widget_gallery gallery-grid-4">
										<h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}><Link to={"contact"}>Contact</Link></h5>
									
									</div>
								</div>

								<div className="col-6 col-lg-3 col-md-5 col-sm-6 footer-col-4">
									<div className="widget widget_gallery gallery-grid-4">
										<h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}> <Link to={"privacy"}>Privacy Policy</Link></h5>
									
									</div>
								</div>

								<div className="col-6 col-lg-3 col-md-5 col-sm-6 footer-col-4">
									<div className="widget widget_gallery gallery-grid-4">
										<h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}> <Link to={"terms-and-conditions"}>Terms & Conditions</Link></h5>
									
									</div>
								</div>

								<div className="col-6 col-lg-3 col-md-5 col-sm-6 footer-col-4">
									<div className="widget widget_gallery gallery-grid-4">
										<h5 className="footer-title" style={ {textDecorationLine: 'Underline'}}> <Link to={"refund"}>Refund Policy</Link></h5>
									
									</div>
								</div>
							</div>
								
							</div>
						</div> 

					</div>
					
				</div>
		
			</>
		);
	}
}

export default PortSingle3;