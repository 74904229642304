import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll';

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Images
import pattern1 from '../../../images/pattern/pt1.png';
import bannerPic2 from '../../../images/background/about.jpg';

import ScrollToTop from "../../elements/ScrollToTop";
  
  
class Contact1 extends Component {
	
	render(){
		return (
			<>
				
				<Header/>
				<ScrollToTop/>
				
				<div className="page-content bg-white">
				    <div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}} >
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">Contact Us</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>Contact</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp1" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx m-sm-t20">
									<h3 className="title-head m-b0">Contact Us For Any <br/>informations</h3>
								</div>
								<div className="row">
									{/* <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
										<div className="icon-box">
											<h5 className="title"><i className="ti-map-alt"></i>Location</h5>		
											<p>KTC 221/223, Khinvasara Trade Center,<br/> Nr. Dange Chowk, Wakad Pune-411033.</p>
										</div>
									</div> */}
									<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.6s">
										<div className="icon-box p-20 feature-container feature-bx1">
											<h5 className="title"><i className="ti-id-badge"></i>Enquiries</h5>		
											<Link  onClick={()=> window.open("mailto:connect@yelloyolk.com")}><h6> connect@yelloyolk.com</h6></Link>
											
										</div>
									</div>

									<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.6s">
										<div className="icon-box p-20 feature-container feature-bx1">
											<h5 className="title"><i className="ti-headphone"></i>Phone</h5>		
											<Link  onClick={()=> window.open("tel:+917767842722")}> <h6>+91-7767842722</h6> </Link>
											
										</div>  
									</div>

									<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.6s">
										<div className="icon-box p-20 feature-container feature-bx1">
											<h5 className="title"><i className="ti-user"></i>Careers</h5>		
											<Link  onClick={()=> window.open("mailto:careers@yelloyolk.com")}> <h6>careers@yelloyolk.com</h6></Link>
											
										</div>  
									</div>

									

									{/* <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.9s">
										<div className="icon-box">
											<h5 className="title"><i className="ti-world "></i>Follow Us</h5>		
											<ul className="list-inline ft-social-bx">
												<li><Link onClick={()=> window.open("https://www.facebook.com/yelloyolkmedia/", "_blank")} className="btn-primary button-sm"><i className="fa fa-facebook"></i></Link></li>
												<li><Link onClick={()=> window.open("https://www.behance.net/yelloyolk_media", "_blank")} className="btn-primary button-sm"><i className="fa fa-behance"></i></Link></li>
												<li><Link onClick={()=> window.open("https://in.linkedin.com/company/yelloyolk-media-pvt-ltd", "_blank")} className="btn-primary button-sm"><i className="fa fa-linkedin"></i></Link></li>
												<li><Link onClick={()=> window.open("https://www.instagram.com/yelloyolk_media/", "_blank")} className="btn-primary button-sm"><i className="fa fa-instagram"></i></Link></li>
											</ul>
										</div>
									</div> */}
								</div>
							</div>
						</div>
						{/* <div className="section-area">
							<Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.1528919866037!2d73.76376101484406!3d18.61219168735578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb0620afd2fb%3A0x52e2176fd4c132cf!2sYelloyolk%20Media%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1615750862199!5m2!1sen!2sin" className="align-self-stretch map-frame d-flex"allowfullscreen></Iframe>
						</div> */}
						<div className="section-area section-sp4" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">		
								<div className="row">
									<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.4s">
										<form id='form' action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdOOxYXgVaowL2X2-Z8BWWnQ_nrDjHY_r2ndyJrEdWZ8QYPXg/formResponse" method="post" target="hidden_iframe" className="contact-bx ajax-form">
											<div className="ajax-message"></div>
											<div className="heading-bx">
												<h2 className="title-head m-b0">Do You Have Any<br/>Questions</h2>
											</div>
											<div className="row placeani">
												<div className="col-lg-4">
													<div className="form-group">
														<div className="input-group">
															<input name="entry.165159204" id='name' data-name="Name" type="text" required className="form-control valid-character" placeholder="Your Name"/>
														</div>
													</div>
												</div>
												<div className="col-lg-4">
													<div className="form-group">
														<div className="input-group"> 
															<input name="entry.33520713" id='email' data-name="Email" type="email" className="form-control" required  placeholder="Your Email "/>
														</div>
													</div>
												</div>
												<div className="col-lg-4">
													<div className="form-group">
														<div className="input-group">
															<input name="entry.204406209" id='phone' data-name="Phone" type="text" required className="form-control int-value" placeholder="Your Phone"/>
														</div>
													</div>
												</div>
												<div className="col-lg-12">
													<div className="form-group">
														<div className="input-group">
															<textarea name="entry.70413042" id='message' data-name="Message" rows="4" className="form-control" placeholder="Type Message" required ></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12">
													<button name="submit" type="submit" value="Submit" className="btn-primary button-lg radius-md"> Send Message</button>
												</div>
											</div>

											
											
										</form>

		
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>

				
				
				<Footer/>
				
			</>
		);
	}
}

export default Contact1;

