import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import Marquee from "react-fast-marquee";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import Count from '../../elements/counter/counter-sensor';

import Testimonial from '../../elements/testimonial/testimonial1';

import ScrollToTop from "../../elements/ScrollToTop";

import SocialMedia from '../../elements/portfolio/social-media';


// Images

import web1 from '../../../images/we-final-promo.mp4';
import p0 from '../../../images/partner/sundaram.png';
import p1 from '../../../images/partner/1.png';
import p2 from '../../../images/partner/2.png';
import p3 from '../../../images/partner/3.png';
import p4 from '../../../images/partner/4.png';
import p5 from '../../../images/partner/5.png';
import p6 from '../../../images/partner/6.png';
import p7 from '../../../images/partner/7.png';
import p8 from '../../../images/partner/8.png';
import p9 from '../../../images/partner/9.png';
import p10 from '../../../images/partner/10.png';
import p11 from '../../../images/partner/11.png';
import p12 from '../../../images/partner/12.png';


class Index extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
	
			
				
			<Header />
			<ScrollToTop/>
				
				
			<div className="page-content">

				    <div className="page-banner home-bnr bg-white"  >
						<div className="container" >
							<div className="page-banner-entry">
								<div className="row align-items-center">
									
									<div className="col-md-6 order-lg-2">
										<div className="home-bnr-img m-b30">

										
											<video className="video" autoPlay muted loop width="100%" >
                                            <source src={web1} type="video/mp4" ></source>
                                            </video>
											

										</div>
									</div>

									<div className="col-md-6   ">
										<div className="home-bnr-title m-b30">
										   
											<h1 className="title m-b30">Bringing innovative ideas to reality </h1>
											<p>Helping Businesses Enhance Customer Experiences by Delivering Innovative UI/UX Solutions | Website Development | Branding Solutions</p>
										

											<p className='mt-10'> <strong> Trusted by Brands across the globe.</strong></p>
											

											<Marquee 

											
											duration={5000}
											background="transparent"											
											axis="X"
											align="center"
											pauseOnHover={false}
											reverse={false}
											speed={80}										
											loop={0}

											
											>

                                               
											    <div className="p1-20 ">
													<img src={p1} width={200} alt="" />
												</div>
												<div className="p1-20 ">
													<img src={p2} width={200} alt="" />
												</div>
												<div className="p1-20 ">
													<img src={p0} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p3} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p4} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p5} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p6} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p7} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p8} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p9} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p10} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p11} width={200} alt="" />
												</div>
												<div className="p1-20">
													<img src={p12} width={200} alt="" />
												</div>
  
              
                                            </Marquee>

											
										
										</div>
								
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>
                   
				<div className="content-block" id="content-area">
			  
					       
						<div className="section-area section-sp2 bg-gray" >
					   	    <div className="container">
								<div className="heading-bx wow fadeIn style2 text-center" data-wow-delay="0.3s">
				                    <span ><h2 className='title-head m-b0'> What we Offer</h2></span>
									
									<p className="m-b0">Bringing various integrated design & technology services under one roof to offer the best digital experience to your customers.</p>
								</div>
							</div>
							<div className="container" >
								
								<div className="row">

								<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.9s">
										<div className="feature-container feature-bx2" >
											<div className="feature-lg m-b20 pb-20">
											<lottie-player src="https://assets3.lottiefiles.com/packages/lf20_3jsfuwcg.json"  background="transparent"  speed="1" width="300px" height="500px"  loop autoplay></lottie-player> 
											</div>
											<div className="icon-content ">
												<h5 className="ttr-tilte">Brand Experience</h5>
												<p>Bringing Innovative ideas <br /> into reality.</p>
												<Link to={"/branding"} className="feature-btn">View More</Link>
											</div>
										</div>
									</div>
									
								  	<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
										<div className="feature-container feature-bx2" >
											<div className="feature-lg m-b20">
											<lottie-player src="https://assets10.lottiefiles.com/packages/lf20_pgalj3ep.json"  background="transparent" speed="1" width="300px" height="300px"  loop autoplay></lottie-player> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Web Design</h5>
												<p>Customized website Designs <br /> for your brand</p>
												<Link to={"/website"} className="feature-btn">View More</Link>
											</div>
										</div>
									</div>
									

									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
										<div className="feature-container feature-bx2 " >
											<div className="feature-lg m-b20">
											<lottie-player src="https://assets3.lottiefiles.com/packages/lf20_dfcawyap.json" background="transparent"  speed="1" width="300px" height="300px"  loop autoplay></lottie-player> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">UI/UX Design</h5>
												<p>Building compelling and <br /> Organic designs</p>
												<Link to={"/uiux"} className="feature-btn">View More</Link>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>

				  



						
						<div className="section-area section-sp2 bg-white" >
					   	    <div className="container align-center ">
								<div className="heading-bx wow fadeIn style2 text-center" data-wow-delay="0.3s">
				                 <h2 className='title-head m-b0'> Featured Work.</h2>
									
								 <p className="m-b0">Take a look at our work:</p> <br />
								 
								</div>
								<SocialMedia /> <br />

								<div className='text-center' >
								 <Link to={"/work"} className="btn-primary text-center title-case button-lg radius-md">View More  <i className="ti-arrow-right"></i></Link>
								</div>
								
							</div>

						</div>

						<div className="section-area section-sp4 bg-gray" >
					   	    <div className="container align-center ">
							    <div className="about-head-bx heading-bx style2 wow m-b30  fadeIn" data-wow-delay="0.3s">
							   
									<div className="about-counter">
										<div className="counter-bx"><Count counter={500}/>+</div>
										<h5>UX/UI Solutions & Websites</h5>
									</div>
									<div className="head-in">
										<h2 className="title-head">Projects Delivered </h2>
										<p>We have managed to make our mark globally by taking ahead our game one step at a time. We come as a complete package that tends to offer experienced valuable solutions.</p>
									</div>
									
                                </div>

								<div className="row">
									
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="row">
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center red">
													<div className="text-secondry">
														<Count counter={10}/>
														<span>+</span>
													</div>
													<span className="counter-text">Domains Served</span>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 text-center m-b30 blue m-xs-b30 m-xs-t0">
													<div className="text-secondry">
														<Count counter={150}/>
														<span>+</span>
													</div>
													<span className="counter-text">Satisfied Clients</span>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center orange m-xs-b30 m-xs-t0">
													<div className="text-secondry">
														<Count counter={7}/>
														<span>+</span>
													</div>
													<span className="counter-text">Countries Covered</span>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center green m-xs-b0">
													<div className="text-secondry">
														<Count counter={5}/>
														<span>+</span>
													</div>
													<span className="counter-text">Years in Industry</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>

						</div>

						
                        <div className="section-area section-sp2 bg-white parallax" >
							<div className="container">
								<div className="heading-bx style2 text-center wow fadeIn" data-wow-delay="0.3s">
									<h2 className="title-head m-b0">What clients talk about us</h2>
								</div>
								
								<Testimonial />
								
							</div>
						</div>  

                <div className="section-area section-sp2 ovpr-dark bg-fix bg-primary" >
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
		
				</div>
				</div>

				<Footer/>
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='cnXapYkboRQ' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default Index;