import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll';


// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';

// Elements

import CaseStudies from '../../elements/case-studies/case-studies2';
import ScrollToTop from "../../elements/ScrollToTop";

// Images
import bannerPic2 from '../../../images/branding.png';
import pattern1 from '../../../images/pattern/pt1.png';
import web from '../../../images/branding.mp4';

import ai from '../../../images/illustrator.svg';
import ps from '../../../images/photoshop.svg';
import ae from '../../../images/ae.png';
import pp from '../../../images/pp.png';
import cd from '../../../images/cd.png';






class PortGrid2 extends Component {
	
	render(){
		return (
			<>
				<Header />
				<ScrollToTop/>
				
				<div className="page-content bg-white">
				<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">Branding</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>Branding</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>						
					
				     	<div className="content-block" id="content-area">

						 <div className="section-area section-sp3">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black m-b0">We design perceptions for the brands.</h2>
									<p className="m-b0">We help you create your unique brand presence in the market to foster your business growth.</p>
								</div>
								<div className="m-b-100">
									<video className="video" autoPlay muted loop width="100%"  >
                                            <source src={web} type="video/mp4" ></source>
                                            </video>
								</div>
							</div>
						</div>

						 <div className="section-area section-sp4">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">What do we serve?</h2>
									<p>Good Branding is the first impression of your business in the market and our aim at Yelloyolk is to make that impression last.</p>
								</div>
								<div className="row">
									<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
										<div className="feature-container m-b30 left feature-bx1 ">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-innovation"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Brand Identity </h5>
												<p>Brand identity is the visible elements of a brand, such as color, design, and logo, that identify and distinguish the brand in consumers' minds.</p>
											</div>
										</div>
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-computer"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Brand Positioning </h5>
												<p>A holistic image of how you'd like your brand to be regarded by customers, as well as a description of your target market backed by research and data.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-line-chart"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Packaging Design</h5>
												<p>This covers material and shape choices, as well as designs, colours, and typefaces used on packaging, a box, a can, a bottle, or any other type of container.</p>
											</div>
										</div>
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-vision"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Brand Consultancy</h5>
												<p>Expert advice that assists businesses in defining, sharpening, and growing their reputation over time resulting growth.</p>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>


						<div className="section-area section-sp4" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black m-b0">How we do it?</h2>
								</div>
								<div className="service-list-bx">
									<div className="service-icon-bx">
									<div className="service-info">
											<h2 className="title">Research.</h2>
											<p>Brand research specifically assists in the creation and curation of strong, distinctive brands.</p>
										</div>
										<div className='service-info' > 
										<lottie-player  src="https://assets2.lottiefiles.com/packages/lf20_bdlrkrqv.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>
										
									</div>
									<div className="service-icon-bx">
									<div className="service-info">
											<h2 className="title">Ideation.</h2>
											<p>Ideation is the creative process of generating, developing, and communicating new ideas, where an idea is understood as a basic element of thought that can be either visual, concrete, or abstract.</p>
										</div>
										
										<div className='service-info' >
										<lottie-player  src="https://assets6.lottiefiles.com/packages/lf20_sRrMSs.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>
										
									</div>
									
									
									<div className="service-icon-bx">

									<div className="service-info">
											<h2 className="title">Feedback & Reviews</h2>
											<p>Feedback is a very powerful tool and key for an effective design. Receiving good quality insights gives a unique perspective on your project.</p>
										</div>

									<div className='service-info'>
										<lottie-player  src="https://assets4.lottiefiles.com/packages/lf20_outxntop.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>

								    

										
										
									</div>

									<div className="service-icon-bx">
										
										<div className="service-info">
											<h2 className="title">Presentation</h2>
											<p>Design presentation is a way to showcase the journey of the work done by a designer. It includes all the processes from research to final product.</p>
										</div>

										<div className='service-info'>
										<lottie-player  src="https://assets2.lottiefiles.com/packages/lf20_uhcfeaa9.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>

									</div>
								</div>
							</div>
						</div>


						<div className="section-area section-sp4">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">Tools We Use</h2>
									<p>We employ the best tools to guarantee high-quality work.</p>
								</div>
								<div className="row">
									
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="row">
                                            <div className="col-md"></div>
											<div className="col-md-2 col-sm-2 col-6 col-mt-3">
											<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
										    	<img src={ai} width={100} alt="XD_best_UIUX_Design_Agency" />
											
												</div>
											
											</div>
											<div className="col-md-2 col-sm-2 col-6 col-mt-3">
												<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
												<img src={ps} width={100} alt="figma_best_UIUX_Design_Agency" />
													
												</div>
											</div>
											<div className="col-md-2 col-sm-2 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b30 m-xs-t0">
												<img src={cd} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>
											<div className="col-md-2 col-sm-2 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b0">
												<img src={pp} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>

											<div className="col-md-2 col-sm-2 col-6 col-mt-3">
											<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
										    	<img src={ae} width={100} alt="XD_best_UIUX_Design_Agency" />
											
												</div>
											
											</div>
										
                                            <div className="col-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>



						<div className="section-area section-sp3">
							<div className="container-fluid">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">Projects we Delivered</h2>
								</div>
								
									
								<CaseStudies />
								
							</div>
						</div>

						<div className="section-area section-sp2 ovpr-dark bg-fix bg-primary">
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md  white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
						
					</div>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default PortGrid2;