import React, { Component } from 'react';

// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

// Images
import pattern1 from '../../../images/pattern/pt1.png';



class BlogDetailsSidebar extends Component {
	
	render(){
		return (
			<>
				<Header />
				<ScrollToTop/>
				
				<div className="page-content bg-white">
				    
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-gray" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="row">
									<div className="col-md-12 col-lg-12 col-xl-12 m-md-b50">
										<div className="blog-lg blog-single">
											
											<div className="info-bx">
												<div className="media-box">
													
												</div>
												<div className="ttr-post-title">
													<h4 className="post-title">Refund Policy.</h4>
												</div>
												<div className="ttr-post-text">
													<p>Your expectation getting a professional service from us and we're really serious to provide you a very creative and quality services and we can guarantee for our work.</p>
													
													<p>When you order a website in a package then hosting & domain control panel credential will be not shared nor shifted to other service provider because it comes free with the website design package, If you needed the credential login details you have to purchase it seprately at the time of ordering the package or later have to pay the charges as applicable and once your website credential is shared to you or shifted to other service provider then our responsibility ceases even if website stop working or arises any errors in future and support will be unavailable.</p>
													
													
													<p>Logo Design : Anytime you can request all work stopped before your first round of revisions and your amount will be refunded within working 07 days to your bank account or directly from the payment gateway from where you have paid the amount as per our convenience.</p>
													
													<p>minimum processing fee 20% will be charged of the package amount, and if you make any revision on a created design or have taken a discount or purchased in a combo package or made a part payment or signup in any sale during the discount or festive offers then refund policy does not apply and no amount will be refunded.</p>
													

													<p>Limitations : The design we make for our client is unqiue so you should not remove/edit the credits "Designed by Yelloyolk Media" and you will be required a written permission in details if you want to change or modify your design by third party or If you want the exclusive rights of the design, You should ask us before you order & we will quote you the price that gives you the exclusive rights and we presume that you should have knowledge the service you are ordering because after ordering we cannot cancel the order and no refund will be available.</p>

                                                     <p>If you are looking for refund then do not communicate via phone call, text messsage, whatsapp or any messaging app and you should email us in details with project name, company name, concern person, bank account details, amount paid date so we can verify the details to refund your amount.</p>

													 <h5>We value your trust in our company, and we will do our best to meet your expectations and look forward to continue to give you the kind of service you deserve.</h5>
													
													

												</div>

												
											</div>
										</div>
										
										
										
										
										
									</div>
														
								</div>					
							</div>					
						</div>					
						
					</div>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetailsSidebar;