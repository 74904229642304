import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

import Testimonial from '../../elements/testimonial/testimonial1';
// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import Count from '../../elements/counter/counter-sensor';
import ClientLogo from "../../elements/client-logo/logo-carousel";

// Images
import bannerPic2 from '../../../images/background/about.jpg';


class About2 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}

	
	
	
	
	
	render(){
		return (
			<>
				<Header/>
				<ScrollToTop/>
				
				<div className="page-content bg-white">
				    <div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">About Us</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>About Us</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>
					<div className="content-block" id="content-area">

					<div className="section-area section-sp4 " >
							<div className="container about-video p-20">
								
								<div className="section-area section-sp4 bg-white" >
					   	    <div className="container align-center ">
							   <div className="about-head-bx heading-bx wow m-b30  fadeIn" data-wow-delay="0.3s">
							   
									<div className="about-counter ">
										<div className="counter-bx"><Count counter={500}/>+</div>
										<h5>UX/UI Solutions & Websites</h5>
									</div>
									<div className="head-in">
										<h2 className="title-head">Projects Delivered </h2>
										<p>We have managed to make our mark globally by taking ahead our game one step at a time. We come as a complete package that tends to offer experienced valuable solutions. </p>
									</div>
									
                                </div>
								<div className="row">
									
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="row">
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center red">
													<div className="text-secondry">
														<Count counter={10}/>
														<span>+</span>
													</div>
													<span className="counter-text">Domains Served</span>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 text-center m-b30 blue m-xs-b30 m-xs-t0">
													<div className="text-secondry">
														<Count counter={150}/>
														<span>+</span>
													</div>
													<span className="counter-text">Satisfied Clients</span>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center orange m-xs-b30 m-xs-t0">
													<div className="text-secondry">
														<Count counter={7}/>
														<span>+</span>
													</div>
													<span className="counter-text">Countries Covered</span>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center green m-xs-b0">
													<div className="text-secondry">
														<Count counter={5}/>
														<span>+</span>
													</div>
													<span className="counter-text">Years in Industry</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>

						</div>
						</div>
						</div>

						<div className="section-area section-sp1 bg-gray">
							<div className="container">
								
								<div className="row">
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
										<div className="feature-container  feature-bx2">
											<div className="feature-lg text-white m-b20">
												<span className="icon-cell"><i className=" flaticon-vision"></i></span> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Our Vision</h5> <hr />
												<p className='p-b30' >To be the pioneers in design and technology in the world.</p>
												
											</div>
											
										</div>
									</div>
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.9s">
										<div className="feature-container feature-bx2">
											<div className="feature-lg text-white m-b20">
												<span className="icon-cell"><i className="flaticon-rocket"></i></span> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Our Mission</h5> <hr />
												<p className='p-b30'>To create one-of-a-kind brands for clients all over the world.</p>
												
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
										<div className="feature-container feature-bx2 ">
											<div className="feature-lg text-white m-b20">
												<span className="icon-cell"><i className="flaticon-growth"></i></span> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Our Values</h5> <hr />
												<p className='p-b30' >Excellence | Teamwork | Creativity | Dependability | Integrity</p>
												
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>

					

                        <div className="section-area section-sp4 bg-white"  >
							<div className="container">
								<div className="row align-items-center">
									<div className="col-md"></div>
									<div className="col-lg-10 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="service-container   ">
											<h2 className="title-head m-b0">Who we are.</h2> <hr />

											<h6>Yelloyolk was founded in 2018 by two graphic designers who envisioned creating one-of-a-kind brands for clients all over the world. Since then, Yelloyolk Media Pvt.Ltd has completed over 500+ unique design and development projects globally.</h6> <br />
											<h6>Based in Pune, India Yelloyolk Media is a rapidly growing branding, design, and IT firm with clients in seven countries. In addition to Branding, Web development UI/UX solutions, Yelloyolk Media provides a wide range of services.</h6> <br />
											<h6>What sets us apart is that we offer a one-stop shop for Design, Strategy, and Development. We work with you to establish a market presence for your distinct brand in order to ensure your company's success.</h6>
											
										</div>	
									</div>
									<div className="col-md"></div>
								</div>
							</div>
						</div>

					  
						
						


						<div className="section-area section-sp4" >
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">Clientele</h2>
									
								</div>
								<ClientLogo />
							</div>
					    </div>

						<div className="section-area section-sp4 bg-white parallax" >
							<div className="container">
								<div className="heading-bx style2 text-center wow fadeIn" data-wow-delay="0.3s">
									<h2 className="title-head m-b0">What clients talk about us</h2>
								</div>
								
								<Testimonial />
								
							</div>
						</div>  
						
						
						
				<div className="section-area section-sp2 ovpr-dark bg-fix bg-primary">
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md  white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
						
						
					</div>
				</div>
				
				
				
				<Footer/>
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='cnXapYkboRQ' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default About2;
