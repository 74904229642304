import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";
import {Link as ScrollTo} from 'react-scroll'
import bannerPic2 from '../../../images/portfolio/diet/5.png';
import CaseStudies from '../../elements/case-studies/case-studies2';
import ScrollToTop from "../../elements/ScrollToTop";

// Images
import diet1 from '../../../images/portfolio/diet/full.png';
import diet2 from '../../../images/portfolio/diet/ad4.png';
import diet4 from '../../../images/portfolio/diet/ad5.png';


class PortSingle3 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header />
				<ScrollToTop/>
				<div className="page-content bg-white">
                <div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">D.I.E.T. SATARA</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>DIET SATARA</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>
					
					<div className="content-block" id="content-area">
						
						

						<div className="section-area section-sp2 bg-white" >
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-12  col-md-12 wow fadeInUp" data-wow-delay="0.4s">
										<div className="heading-bx">
										    
											<h3 className="title-head m-b0">Dnyanshree Institute of Engineering and Technology</h3> <hr />
											<p className="m-b30">  Dnyanshree Institute of Engineering and Technology (D. I. E. T.) is a hub of knowledge and technical facilities for aspiring engineering students. The college offers Bachelor of Engineering in Mechanical, Civil, Electronics & Telecommunication, Electrical and Computer Science. Diploma courses offered are Mechanical and Electronics & Telecommunication. D. I. E. T. has state of the art infrastructure, well-furnished classrooms, fully equipped labs, library are always available for academic activities.</p>

											<h5 className="title-head m-b0">What we did?</h5>
											<p className="m-b30">Yelloyolk Media worked closely with Dnyanshree Institute of Engineering and Technology (DIET) to perform rebranding exercise on campus. Yelloyolk Media fully revamped the branding of the institution which incluedes Website Design with UIUX solutions, Offline Branding Collaterals, Print Collaterals, Admission Brochures etc.  </p>
											
										
										</div>
									</div>
					
									
									
								</div>
							</div>
						</div> 

	


						<div className="section-area bg-white">
							<div className="container">
							
								<div className="row">
									
                                    <div className="col-lg-12 m-b20">
										<img src={diet2} alt=""/>
									</div>
                                    <div className="col-lg-12 m-b20">
										<img src={diet4} alt=""/>
									</div>
									<div className="col-lg-12 m-b20">
										<img src={diet1} alt=""/>
									</div>
                                   
                                   
								</div>
							</div>
						</div>
						
						
						
						
						
						<div className="section-area section-sp2 bg-gray" >
							<div className="container-max wow fadeIn" data-wow-delay="0.4s">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">Other Projects</h2>
								</div>
								
								<CaseStudies />
								
							</div>
						</div>
						
						<div className="section-area section-sp2 bg-white" >
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">We Are Known By The <br/>Companies We Keep</h2>
								</div>
								<ClientLogo />
							</div>
						</div>

						<div className="section-area section-sp2 ovpr-dark bg-fix bg-primary" >
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md  white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
						
					</div>
					
				</div>
				
				<Footer />
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='cnXapYkboRQ' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default PortSingle3;