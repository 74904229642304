import React, { Component } from 'react';
import Slider from "react-slick"; 

// Images
import TestiPic1 from "../../../images/testimonials/hrishikesh.jpg"
import TestiPic2 from "../../../images/testimonials/rahul.png"
import TestiPic3 from "../../../images/testimonials/chandni.png"
import TestiPic5 from "../../../images/testimonials/shilpa.png"
import TestiPic6 from "../../../images/testimonials/meghana.png"
import TestiPic7 from "../../../images/testimonials/pranav.png"

class Testimonial2 extends Component{
	render(){
		
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				

				<Slider {...settings} className="testimonial-carousel style2 owl-btn-center-lr owl-btn-1 dots-style-1 dots-pr arrow">
					<div className="slider-item">
						<div className="testimonial-bx style2 orange">
							<div className="testimonial-content">
								<h5 className="title">Best product ever !</h5>
								<div className="testimonial-rating"> 
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
								</div>
								<p>I needed branding done for my clinic, and Im glad I found just the perfect company for it. I was amazed at the promptness and proficiency shown by Yelloyolk Media. Definitely recommended !!!</p>
							</div>
							<div className="client-info">
								<div className="testimonial-thumb">
									<img src={TestiPic1} alt=""/>
								</div>
								<div className="testimonial-info">
									<h6 className="name">Dr. Hrishikesh Patkar</h6>
									<p>Orthopedic Surgeon</p>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="testimonial-bx style2 red">
							<div className="testimonial-content">
								<h5 className="title">Great Support !</h5>
								<div className="testimonial-rating"> 
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
								</div>
								<p>We are very happy with our new website. Thank you Yelloyolk Media for your great service and support at each step. We would highly recommend Yelloyolk Media Pvt. Ltd. to anyone looking to build a new website.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-thumb">
									<img src={TestiPic6} alt=""/>
								</div>
								<div className="testimonial-info">
									<h6 className="name">Meghana Gund</h6>
									<p>Cloud9 ebiz Pvt. Ltd.</p>
								</div>
							</div>
						</div>
					</div>

					<div className="slider-item">
						<div className="testimonial-bx style2 blue">
							<div className="testimonial-content">
								<h5 className="title">Great Support !</h5>
								<div className="testimonial-rating"> 
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
								</div>
								<p>Well I had my Branding & Website work done from Yelloyolk Media. It was a very good experience overall for me. The logo was nice and apt. They did all the changes right in front of me & it was pretty fast. The creativity and advice was perfect. Overall I would say, worth it.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-thumb">
									<img src={TestiPic7} alt=""/>
								</div>
								<div className="testimonial-info">
									<h6 className="name">Dr. Pranav Radkar</h6>
									<p>Photon Eye Center</p>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="testimonial-bx style2 red">
							<div className="testimonial-content">
								<h5 className="title">Best Services</h5>
								<div className="testimonial-rating"> 
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
								</div>
								<p>Logo is always a face of the business/ company. Yelloyolk is highly a professional team with commitment and dedication towards their clients. Neha, Shubham and the team are always motivated to help. Surely recommending to avail their services. Thank you. All the best.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-thumb">
									<img src={TestiPic2} alt=""/>
								</div>
								<div className="testimonial-info">
									<h6 className="name">Rahul Parmaj</h6>
									<p>Turbotrack</p>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="testimonial-bx style2 orange">
							<div className="testimonial-content">
								<h5 className="title">Great Support</h5>
								<div className="testimonial-rating"> 
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
								</div>
								<p>Highly skilled and professional. Shubham, Neha and the entire team have always been cooperative in delivering the work ontime, fully reaching client's expectations.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-thumb">
									<img src={TestiPic3} alt=""/>
								</div>
								<div className="testimonial-info">
									<h6 className="name">Chandani Chowdhary</h6>
									<p>Rajdhani Construction</p>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="testimonial-bx style2 blue">
							<div className="testimonial-content">
								<h5 className="title">Best product ever !</h5>
								<div className="testimonial-rating"> 
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
									<span className="fa fa-star"></span>
								</div>
								<p>Highly professional!!
My experience with Yelloyolk is great. The design and package provided by them are best. They completed my work as per my requirement within given period of time.
Mr. Mufaddal is very co-operative my special thanks to him and Yelloyolk team.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-thumb">
									<img src={TestiPic5} alt=""/>
								</div>
								<div className="testimonial-info">
									<h6 className="name">Shillpa Kankani</h6>
									<p>NLP Coach</p>
								</div>
							</div>
						</div>
					</div>

					
					
				</Slider>
			</>
		);
	}
}

export default Testimonial2;