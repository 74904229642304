import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll';


// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';

// Elements

import CaseStudies from '../../elements/case-studies/case-studies2';
import ScrollToTop from "../../elements/ScrollToTop";

// Images
import bannerPic2 from '../../../images/web-dev.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import web from '../../../images/web_tools_best_web_design_company.mp4';

import React1 from '../../../images/react-1.svg';
import Angular from '../../../images/angular-1.svg';
import Js from '../../../images/js-1.svg';
import wordpress from '../../../images/wordpress-1.svg';
import npm from '../../../images/npm-1.svg';
import css from '../../../images/css-1.svg';
import html from '../../../images/html-1.svg';
import php from '../../../images/php-1.svg';






class PortGrid2 extends Component {
	
	render(){
		return (
			<>
				<Header />
				<ScrollToTop/>
				
				<div className="page-content bg-white">
				<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">Web Design</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>Web Design</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>						
					
				     	<div className="content-block" id="content-area">

						 <div className="section-area section-sp3">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black m-b0">Creating Digital Experiences</h2>
									<p className="m-b0">We've been in this industry for a while now, learning and collecting years of experience in Design, Strategy and Development. All that, empowered by our passion and ambition, helps us to create or grow your brand & product to its full potential.</p>
								</div>
								<div className="m-b-100">
									
									<video className="video" autoPlay muted loop width="100%"  >
                                            <source src={web} type="video/mp4" ></source>
                                            </video>
								</div>
							</div>
						</div>

						 <div className="section-area section-sp4">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">What do we serve?</h2>
									<p>Websites are an integral part of your
business. Our customized web designing
services will help your brand enhance your digital presence and foster your growth.</p>
								</div>
								<div className="row">
									<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
										<div className="feature-container m-b30 left feature-bx1 ">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-innovation"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Web Design</h5>
												<p> Enhancing the visual experience of your website by designing layouts, content creation, & Graphics.</p>
											</div>
										</div>
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-computer"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Web Development </h5>
												<p>Creating high-performance, feature-rich online experiences that are easy to use.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-line-chart"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Website Interactions.</h5>
												<p>Transforming how your customers interact with your website.</p>
											</div>
										</div>
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-vision"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Animations.</h5>
												<p>Bringing your website to life by transforming complex designs into animations</p>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>


						<div className="section-area section-sp4" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black m-b0">How we do it?</h2>
								</div>
								<div className="service-list-bx">
									<div className="service-icon-bx">
									    <div className="service-info">
											<h2 className="title">Understanding Objectives.</h2>
											<p>What Website Goals Should You Set? Once you have a clear understanding of the purpose of your site you can set your goals.</p>
										</div>
										<div className='service-info' > 
										<lottie-player  src="https://assets7.lottiefiles.com/packages/lf20_h8tcmve9.json"  background="transparent"  speed="1"  style={{transform: "scale(1)"}}  loop autoplay></lottie-player>
										</div>
										
									</div>
									<div className="service-icon-bx">
									    <div className="service-info">
											<h2 className="title">Choosing Right Technology.</h2>
											<p>One of the most important steps to take when it comes to developing a website is to pick the right tech stack.</p>
										</div>
										
										<div className='service-info' >
										<lottie-player  src="https://assets10.lottiefiles.com/packages/lf20_v33gmcrb.json"  background="transparent"  speed="1"  style={{transform: "scale(1)"}}  loop autoplay></lottie-player>
										</div>
										
									</div>
									<div className="service-icon-bx">
										
										<div className="service-info">
											<h2 className="title">Documented Coding</h2>
											<p>A cornerstone of good code is maintainability, achieved through understandable, legible documentation.</p>
										</div>

										<div className='service-info' >
										<lottie-player  src="https://assets3.lottiefiles.com/packages/lf20_l4fgppor.json"  background="transparent"  speed="1"  style={{transform: "scale(1)"}}  loop autoplay></lottie-player>
										</div>

									</div>
									
									<div className="service-icon-bx">

								    	<div className="service-info">
											<h2 className="title">Testing</h2>
											<p> Web testing is a practice to test websites or web applications for potential bugs. It's a complete testing of websites before making live.</p>
										</div>

										<div className='service-info'>
										<lottie-player  src="https://assets2.lottiefiles.com/packages/lf20_c8pgmddl.json"  background="transparent"  speed="1"  style={{transform: "scale(1)"}}  loop autoplay></lottie-player>
										</div>
										
									</div>
									<div className="service-icon-bx">
										
										<div className="service-info">
											<h2 className="title">Project Deployment.</h2>
											<p>Deployment refers to moving an object to a place where some action can be performed on it. In the case of software development, deployment means making an application ready for delivery.</p>
										</div>
										<div className='service-info'>
										<lottie-player  src="https://assets3.lottiefiles.com/packages/lf20_ojjrkacr.json"  background="transparent"  speed="1"  style={{transform: "scale(0.9)"}}  loop autoplay></lottie-player>
										</div>
									</div>
								</div>
							</div>
						</div>


						<div className="section-area section-sp4">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">Technologies We Use</h2>
									<p>We employ the best tools to guarantee high-quality work.</p>
								</div>
								<div className="row">
									
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="row">
											<div className="col-md-3  col-sm-3 col-6 col-mt-3">
											<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
										    	<img src={React1} width={100} alt="XD_best_UIUX_Design_Agency" />
											
												</div>
											
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
												<img src={Angular} width={100} alt="figma_best_UIUX_Design_Agency" />
													
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b30 m-xs-t0">
												<img src={Js} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b0">
												<img src={wordpress} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>

											<div className="col-md-3  col-sm-3 col-6 col-mt-3">
											<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
										    	<img src={npm} width={100} alt="XD_best_UIUX_Design_Agency" />
											
												</div>
											
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
												<img src={php} width={100} alt="figma_best_UIUX_Design_Agency" />
													
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b30 m-xs-t0">
												<img src={html} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b0">
												<img src={css} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



						<div className="section-area section-sp3">
							<div className="container-fluid">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">Projects We Delivered</h2>
								</div>
								
									
								<CaseStudies />
								
							</div>
						</div>


						<div className="section-area section-sp2 ovpr-dark bg-fix bg-primary" >
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md  white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
						
					</div>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default PortGrid2;