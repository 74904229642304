import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../images/logo.png';

class Footer2 extends Component{
	render(){
		return(
			<>
				<footer className="footer-white">
					<div className="footer-top footer-style2 bt0">
						<div className="container">
							<div className="row">
								<div className="col-12 col-lg-4 col-md-4 col-sm-6">
								<div className="widget widget_info">
										
								     	<Link to={"/"}><img src={logo} width="200px" alt=""/></Link>
								       
									</div>
									<div className="widget">		
										
									    <ul className="list-inline ft-social-bx">
											<li ><Link onClick={()=> window.open("https://www.facebook.com/yelloyolkmedia/", "_blank")} className="btn-primary button-md"><i className="fa fa-facebook"></i></Link></li>
											<li><Link onClick={()=> window.open("https://www.behance.net/yelloyolk_media", "_blank")} className="btn-primary button-md"><i className="fa fa-behance"></i></Link></li>
									  		<li><Link onClick={()=> window.open("https://in.linkedin.com/company/yelloyolk-media-pvt-ltd", "_blank")} className="btn-primary button-md"><i className="fa fa-linkedin"></i></Link></li>
											<li><Link onClick={()=> window.open("https://www.instagram.com/yelloyolk_media/", "_blank")} className="btn-primary button-md"><i className="fa fa-instagram"></i></Link></li>
											
										</ul>
									</div>
								</div>
								<div className="col-6 col-lg-4 col-md-4 col-sm-6">
									<div className="widget footer_widget">
									<h5 className="footer-title">Useful Links</h5>
										<ul>
											
											<li><Link to={"/privacy"}>Privacy Policy</Link></li>
											<li><Link to={"/refund"}>Refund Policy</Link></li>
											<li><Link to={"/terms-and-conditions"}>Terms & Conditions</Link></li>
											<li><Link to={"/sitemap"}>Sitemap</Link></li>
										</ul>
									</div>
								</div>
								<div className="col-6 col-lg-4 col-md-2 col-sm-6">
									<div className="widget footer_widget">
										<h5 className="footer-title">Services</h5>
										<ul>
											<li><Link to={"uiux"}>UI/UX</Link></li>
											<li><Link to={"website"}>Web Design</Link></li>
											<li><Link to={"branding"}>Branding</Link></li>
											<li><Link to={"work"}>Our Work</Link></li>
										</ul>
									</div>
								</div>
								{/* <div className="col-12 col-lg-3 col-md-5 col-sm-6 footer-col-4">
									<div className="widget widget_gallery gallery-grid-4">
										<h5 className="footer-title">Our Work</h5>
										<GalleryImg />
									</div>
								</div> */}
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 text-center"> © 2018-2024<span className="text-primary">Yelloyolk Media Pvt. Ltd.</span>  All Rights Reserved.</div>
							</div>
						</div>
					</div>
				</footer>
				
			</>
		);
	}
}

export default Footer2;