import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll';


// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';

// Elements

import CaseStudies from '../../elements/case-studies/case-studies2';
import ScrollToTop from "../../elements/ScrollToTop";

// Images
import bannerPic2 from '../../../images/banner/uiux.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import figma from '../../../images/figma.svg';
import photoshop from '../../../images/photoshop.svg';
import illustrator from '../../../images/illustrator.svg';
import XD from '../../../images/xd.svg';


class PortGrid2 extends Component {
	
	render(){
		return (
			<>
				<Header />
				<ScrollToTop/>
				
				<div className="page-content bg-white">
				<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">UIUX</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>UIUX</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>						
					
				     	<div className="content-block" id="content-area">

						
						 <div className="section-area section-sp4">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">What do we serve?</h2>
								</div>
								<div className="row">
									<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
										<div className="feature-container m-b30 left feature-bx1 ">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-innovation"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">UI Design & Development</h5>
												<p>We make your website and mobile app delightful to use by enhancing their User Interface.</p>
											</div>
										</div>
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-computer"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">UX Design & User Research </h5>
												<p>Our people-first, appealing, and pleasant designs address the needs of modern businesses.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-smartphone"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Design System.</h5>
												<p>A design system is a collection of reusable components assembled together to build products.</p>
											</div>
										</div>
										<div className="feature-container left m-b30 feature-bx1">
											<div className="feature-lg text-white m-b20">
												<Link to={"#"} className="icon-cell"><i className="flaticon-puzzle"></i></Link> 
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">UX Consulting.</h5>
												<p>UX consulting is doing research on your product, identifying weaknesses in the system, and fixes.</p>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>


						<div className="section-area section-sp4" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black m-b0">How we do it?</h2>
								</div>
								<div className="service-list-bx">
									<div className="service-icon-bx">
										<div className="" > 
										<lottie-player  src="https://assets3.lottiefiles.com/packages/lf20_xcwwye4d.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>
										<div className="service-info">
											<h2 className="title">Finding the Problem.</h2>
											<p>With ample research related to your brand we discover the purpose, Target Audience and their expectations.</p>
										</div>
									</div>
									<div className="service-icon-bx">
										<div className="" >
										<lottie-player  src="https://assets10.lottiefiles.com/packages/lf20_rfpx5hes.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>
										<div className="service-info">
											<h2 className="title">Defining the Problem.</h2>
											<p>Using case studies and user personas related to your brand.</p>
											
										</div>
									</div>
									<div className="service-icon-bx">
										<div >
										<lottie-player  src="https://assets6.lottiefiles.com/packages/lf20_R7CRMj.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>
										<div className="service-info">
											<h2 className="title">Ideation & Design</h2>
											<p>Documenting ideas to be used for your brand on paper .</p>
										</div>
									</div>
									<div className="service-icon-bx">
										<div>
										<lottie-player  src="https://assets10.lottiefiles.com/private_files/lf30_0ZSQQP.json"  background="transparent"  speed="1"  style={{transform: "scale(1)"}}  loop autoplay></lottie-player>
										</div>
										<div className="service-info">
											<h2 className="title">Prototyping.</h2>
											<p>Prototype/Wireframing-Creating a sample of the original product.</p>
										</div>
									</div>
									<div className="service-icon-bx">
										<div>
										<lottie-player  src="https://assets2.lottiefiles.com/packages/lf20_c8pgmddl.json"  background="transparent"  speed="1"  style={{transform: "scale(.9)"}}  loop autoplay></lottie-player>
										</div>
										<div className="service-info">
											<h2 className="title">Testing</h2>
											<p>As a part of the mock trial we ensure every feature is working with precision .</p>
										</div>
									</div>
								</div>
							</div>
						</div>


						<div className="section-area section-sp4">
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">Tools We Use</h2>
									<p>We employ the best tools to guarantee high-quality work.</p>
								</div>
								<div className="row">
									
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="row">
											<div className="col-md-3  col-sm-3 col-6 col-mt-3">
											<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
										    	<img src={XD} width={100} alt="XD_best_UIUX_Design_Agency" />
											
												</div>
											
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 text-center m-b30 m-xs-b30 m-xs-t0">
												<img src={figma} width={80} alt="figma_best_UIUX_Design_Agency" />
													
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b30 m-xs-t0">
												<img src={illustrator} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-6 col-mt-3">
												<div className="counter-style-3 m-b30 text-center m-xs-b0">
												<img src={photoshop} width={100} alt="illustrator_best_UIUX_Design_Agency" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



						<div className="section-area section-sp3">
							<div className="container-fluid">
								<div className="heading-bx text-center">
									<h2 className="title-head text-black">Projects we Delivered</h2>
								</div>
								
									
								<CaseStudies />
								
							</div>
						</div>

                    

						<div className="section-area section-sp2 ovpr-dark bg-fix bg-primary" >
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md  white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
						
					</div>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default PortGrid2;