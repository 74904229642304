import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";
import {Link as ScrollTo} from 'react-scroll'
import bannerPic2 from '../../../images/portfolio/nutri/5.png';
import CaseStudies from '../../elements/case-studies/case-studies2';
import ScrollToTop from "../../elements/ScrollToTop";

// Images

import I1 from '../../../images/portfolio/nutri/1.png';
import pec2 from '../../../images/portfolio/nutri/2.png';
import pec3 from '../../../images/portfolio/nutri/3.png';
import pec4 from '../../../images/portfolio/nutri/4.png';
import pec6 from '../../../images/portfolio/nutri/5.png';
import pec5 from '../../../images/portfolio/nutri/6.png';




class PortSingle3 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header />
				<ScrollToTop/>
				<div className="page-content bg-white">
                <div className="page-banner ovbl-dark scale page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">Nutritive</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>Nutritive</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>
					
					<div className="content-block" id="content-area">
						
						
						<div className="section-area section-sp2 bg-white" >
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6  col-md-12 wow fadeInUp" data-wow-delay="0.4s">
										<div className="heading-bx">
										    
											<h3 className="title-head m-b0">Nutritive</h3>
											<p className="m-b30">Located in Hyderabad, Nutritive offers wide range of products that are healthy and rich in nutrients. Nutritive operates through dedicated stores in the city as well as through online marketplaces. </p>

										
										</div>
									</div>

                                    <div className="col-lg-6  col-md-12 wow fadeInUp" data-wow-delay="0.4s">
										<div className="heading-bx">
										    
										
											<h5 className="title-head m-b0">Task</h5>
											<p className="m-b30">Our main task was to design the brand identity in a unique way which can stand out from the competition and appeals to the new age customers. We did a thorough research and came out with a design which can be easily identified and is refreshing. </p>
											
										
										</div>
									</div>
					
									
									
								</div>
							</div>
						</div> 

	


						<div className="section-area bg-white">
							<div className="container">
							
								<div className="row">
									
                                    <div className="col-lg-12 m-b20">
										<img src={I1} alt=""/>
									</div>
                                    <div className="col-lg-12 m-b20">
										<img src={pec2} alt=""/>
									</div>
									<div className="col-lg-12 m-b20">
										<img src={pec3} alt=""/>
									</div>
                                    <div className="col-lg-12 m-b20">
										<img src={pec4} alt=""/>
									</div>
                                    <div className="col-lg-12 m-b20">
										<img src={pec6} alt=""/>
									</div>
                                    <div className="col-lg-12 m-b20">
										<img src={pec5} alt=""/>
									</div>
                                  
                                   
                                   
								</div>
							</div>
						</div>
						
						
						
						
						
						<div className="section-area section-sp2 bg-gray" >
							<div className="container-max wow fadeIn" data-wow-delay="0.4s">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">Other Projects</h2>
								</div>
								
								<CaseStudies />
								
							</div>
						</div>
						
						<div className="section-area section-sp2 bg-white" >
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">We Are Known By The <br/>Companies We Keep</h2>
								</div>
								<ClientLogo />
							</div>
						</div>

						<div className="section-area section-sp2 ovpr-dark bg-fix bg-primary" >
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md  white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
						
					</div>
					
				</div>
				
				<Footer />
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='cnXapYkboRQ' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default PortSingle3;