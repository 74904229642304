import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import {Link as ScrollTo} from 'react-scroll'

// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";
import ScrollToTop from "../../elements/ScrollToTop";



// Images
import bgPic2 from '../../../images/we-final-promo.mp4';
import PortImg1 from './../../../images/work-thumb/photon.jpg';
import bannerPic2 from '../../../images/background/about.jpg';
import portImg4 from '../../../images/work-thumb/kf.jpg';
import portImg5 from '../../../images/work-thumb/diet.jpg';
import portImg6 from '../../../images/work-thumb/sin.png';
import portImg7 from '../../../images/work-thumb/beveracious1.jpg';
import portImg8 from '../../../images/work-thumb/max1.png';
import portImg9 from '../../../images/work-thumb/nu1.jpg';
import portImg10 from '../../../images/work-thumb/presume1.png';
import portImg11 from '../../../images/work-thumb/ijcpms1.jpg';
import portImg14 from '../../../images/work-thumb/igi.jpg';
import portImg13 from '../../../images/work-thumb/sk.jpg';
import portImg19 from '../../../images/work-thumb/hotbrews1.png';
import portImg20 from '../../../images/work-thumb/ador.jpg';
import portImg21 from '../../../images/work-thumb/webook.jpg';
import portImg22 from '../../../images/work-thumb/advent.jpg';


class PortSingle3 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header />
				<ScrollToTop/>
				
				<div className="page-content bg-white">
				<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
					    <div className="container">
							<div className="page-banner-entry text-center">
								<h1 className="text-white text-center">Work</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Home</Link></li>
										<li>Work</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
						
					</div>
					<div className="content-block" id="content-area" >
						
						<div className="section-area section-sp4 bg-white" >
						    <div className="container">
								<div className="heading-bx wow fadeIn style3 text-center" data-wow-delay="0.3s">
				                    <span className='title-head m-b0'><h3> What we Offer.</h3></span>
									
									<p className="m-b0">Our people enjoy a personal stake in your continued success and take pride in what we do. Take a look at our work:</p>
								</div>
							</div>

							<div className="container">
								<div className="row align-items-center">

								<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.4s">
									<div className="portfolio-box style-2 ">
								      <div className="portfolio-media">
									    <img src={portImg21} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/webook"}>WeBook</Link></h4>
									    <span className="exe-title">App UI/UX Design</span>	
							    	</div>
							</div>
									</div>

									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg22} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/advent"}>Advent Technologies</Link></h4>
									    <span className="exe-title">Brand Identity | Web Design</span>	
							    	</div>
							</div>
									</div>

									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.4s">
									<div className="portfolio-box style-2 ">
								      <div className="portfolio-media">
									    <img src={portImg5} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/diet"}>Dnyanshree Institute</Link></h4>
									    <span className="exe-title">Branding | Web Design</span>	
							    	</div>
							</div>
									</div>

									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg8} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/maxcess"}>Maxcess</Link></h4>
									    <span className="exe-title">Brand Identity</span>	
							    	</div>
							</div>
									</div>
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg9} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/nutritive"}>Nutritive</Link></h4>
									    <span className="exe-title">Branding</span>	
							    	</div>
							</div>
									</div>
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg10} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/presume-trade"}>Presume Trade</Link></h4>
									    <span className="exe-title">Brand Identity</span>	
							    	</div>
							</div>
									</div>

									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2 ">
								      <div className="portfolio-media">
									    <img src={portImg6} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/social-innovation"}>Social Innovation LLC</Link></h4>
									    <span className="exe-title">Branding | Web Design</span>	
							    	</div>
							</div>
									</div>



									

									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg20} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/ador"}>Ador Welding Ltd.</Link></h4>
									    <span className="exe-title">Brand Identity | Web Design</span>	
							    	</div>
							</div>
									</div>

									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2 ">
								      <div className="portfolio-media">
									    <img src={PortImg1} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/photon"}>Photon Eye Center</Link></h4>
									    <span className="exe-title">Branding | Web Design</span>	
							    	</div>
							</div>
									</div>

									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg7} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/beveracious"}>Beveracious</Link></h4>
									    <span className="exe-title">Brand Identity/Logo</span>	
							    	</div>
							</div>
									</div>
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg11} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/ijcpms"}>IJCPMS</Link></h4>
									    <span className="exe-title">Brand Identity/Logo</span>	
							    	</div>
							</div>
									</div>
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg19} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/hotbrews"}>Hotbrews</Link></h4>
									    <span className="exe-title">Brand Identity/Logo</span>	
							    	</div>
							</div>
									</div>
									
									
									
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg4} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/karwaan"}>Karwaan Foundation</Link></h4>
									    <span className="exe-title">Branding | Website Redesign</span>	
							    	</div>
							</div>
									</div>
									
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2 ">
								      <div className="portfolio-media">
									    <img src={portImg14} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/igi"}>Indira Group of Institutes</Link></h4>
									    <span className="exe-title">Branding | Web Design</span>	
							    	</div>
							</div>
									</div>
									<div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.8s">
									<div className="portfolio-box style-2">
								      <div className="portfolio-media">
									    <img src={portImg13} alt=""/>
								      </div>
							       	<div className="portfolio-info">
									    <h4 className="title"><Link to={"/shilpa-kankani"}>Mrs. Shilpa Kankani</Link></h4>
									    <span className="exe-title">Website Redesign</span>	
							    	</div>
							</div>
									</div>
									
						
								</div>
							</div>
						</div>
						
						
						
						<div className="section-area section-sp2 bg-white" >
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">We Are Known By The <br/>Companies We Keep</h2>
								</div>
								<ClientLogo />
							</div>
						</div>

						<div className="section-area section-sp2 ovpr-dark bg-fix bg-primary" style={{backgroundImage: "url("+bgPic2+")", backgroundPosition: "center"}}>
					<div className="container">
						<div className="heading-bx text-center text-white style3">
							<h2 className="title-head m-b0">Let's Talk about your Ideas. </h2>
							<p className="m-b20">We would love hearing from you. Let's get on a Discovery call</p>
							<Link onClick={()=> window.open("https://wa.me/917767842722")} className="btn-secondry button-lg radius-md  white">Get Started Now</Link>
						</div>
						
					</div>
				</div>
						
					</div>
					
				</div>
				
				<Footer />
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='cnXapYkboRQ' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default PortSingle3;