import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";
import { Link as ScrollTo } from "react-scroll";
import bannerPic2 from "../../../images/portfolio/webook/webook-1.png";
import CaseStudies from "../../elements/case-studies/case-studies2";
import ScrollToTop from "../../elements/ScrollToTop";

// Images
import webook1 from "../../../images/portfolio/webook/1.png";
import webook2 from "../../../images/portfolio/webook/2.png";
import webook3 from "../../../images/portfolio/webook/3.png";
import webook4 from "../../../images/portfolio/webook/4.png";
import webook5 from "../../../images/portfolio/webook/5.png";
import webook6 from "../../../images/portfolio/webook/6.png";
import webook7 from "../../../images/portfolio/webook/7.png";
import webook8 from "../../../images/portfolio/webook/8.png";
import webook9 from "../../../images/portfolio/webook/9.png";
import webook10 from "../../../images/portfolio/webook/10.png";
import webook11 from "../../../images/portfolio/webook/11.png";
import webook12 from "../../../images/portfolio/webook/12.png";

const Webook = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Header />
      <ScrollToTop />
      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax"
          style={{ backgroundImage: `url(${bannerPic2})` }}>
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1 className="text-white text-center">Webook</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/">
                      <i className="fa fa-home"></i>Home
                    </Link>
                  </li>
                  <li>Webook</li>
                </ul>
              </div>
            </div>
          </div>
          <ScrollTo
            smooth={true}
            to="content-area"
            className="banner-bottom scroll-page">
            <i className="ti-arrow-down"></i>
          </ScrollTo>
        </div>

        <div className="content-block" id="content-area">
          <div className="section-area bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 m-b20">
                  <img src={webook1} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook2} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook3} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook4} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook5} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook6} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook7} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook8} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook9} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook10} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook11} alt="" />
                </div>
                <div className="col-lg-12 m-b20">
                  <img src={webook12} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="section-area section-sp2 bg-gray">
            <div className="container-max wow fadeIn" data-wow-delay="0.4s">
              <div className="heading-bx text-center">
                <h2 className="title-head m-b0">Other Projects</h2>
              </div>

              <CaseStudies />
            </div>
          </div>

          <div className="section-area section-sp2 bg-white">
            <div className="container">
              <div className="heading-bx text-center">
                <h2 className="title-head m-b0">
                  We Are Known By The <br />
                  Companies We Keep
                </h2>
              </div>
              <ClientLogo />
            </div>
          </div>

          <div className="section-area section-sp2 ovpr-dark bg-fix bg-primary">
            <div className="container">
              <div className="heading-bx text-center text-white style3">
                <h2 className="title-head m-b0">
                  Let's Talk about your Ideas.
                </h2>
                <p className="m-b20">
                  We would love hearing from you. Let's get on a Discovery call
                </p>
                <Link
                  onClick={() => window.open("https://wa.me/917767842722")}
                  className="btn-secondry button-lg radius-md  white">
                  Get Started Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="cnXapYkboRQ"
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default Webook;
