import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images

import PortImg1 from "./../../../images/work-thumb/advent.jpg";
import PortImg2 from "./../../../images/work-thumb/diet.jpg";
import PortImg3 from "./../../../images/work-thumb/webook.jpg";
import PortImg4 from "./../../../images/work-thumb/max1.png";
import PortImg5 from "./../../../images/work-thumb/ador.jpg";
import PortImg6 from "./../../../images/work-thumb/ijcpms1.jpg";

function PortfolioContent() {
  return (
    <>
      <div className="container">
        <div className="row sp20">
          <div className="action-card col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="portfolio-box style-2">
              <div className="portfolio-media">
                <Link to={"/diet"}>
                  <img src={PortImg2} alt="" />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/diet"}>Dnyanshree Institute </Link>
                </h4>
                <span className="exe-title">Branding | Website</span>
              </div>
            </div>
          </div>

          <div className="action-card col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="portfolio-box style-2">
              <div className="portfolio-media">
                <Link to={"/nutritive"}>
                  <img src={PortImg3} alt="" />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/webook"}>Webook</Link>
                </h4>
                <span className="exe-title">App UI/UX Design</span>
              </div>
            </div>
          </div>

          <div className="action-card col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="portfolio-box style-2">
              <div className="portfolio-media">
                <Link to={"/advent"}>
                  <img src={PortImg1} alt="" />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/advent"}>Advent Technologies</Link>
                </h4>
                <span className="exe-title">Branding | Website</span>
              </div>
            </div>
          </div>

          <div className="action-card col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="portfolio-box style-2">
              <div className="portfolio-media">
                <Link to={"/maxcess"}>
                  <img src={PortImg4} alt="" />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/maxcess"}>Maxcess</Link>
                </h4>
                <span className="exe-title">Branding</span>
              </div>
            </div>
          </div>

          <div className="action-card col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="portfolio-box style-2">
              <div className="portfolio-media">
                <Link to={"/ador"}>
                  <img src={PortImg5} alt="" />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/ador"}>Ador Welding Ltd.</Link>
                </h4>
                <span className="exe-title">
                  Branding | Packaging | Website
                </span>
              </div>
            </div>
          </div>

          <div className="action-card col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="portfolio-box style-2">
              <div className="portfolio-media">
                <Link to={"/ijcpms"}>
                  <img src={PortImg6} alt="" />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/ijcpms"}>IJCPMS</Link>
                </h4>
                <span className="exe-title">Branding</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

class PortMasonry extends Component {
  render() {
    return (
      <>
        <PortfolioContent />
      </>
    );
  }
}
export default PortMasonry;
