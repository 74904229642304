import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import PartnerLogo1 from '../../../images/partner/1.png';
import PartnerLogo2 from '../../../images/partner/2.png';
import PartnerLogo3 from '../../../images/partner/3.png';
import PartnerLogo4 from '../../../images/partner/4.png';
import PartnerLogo5 from '../../../images/partner/5.png';
import PartnerLogo6 from '../../../images/partner/6.png';
import PartnerLogo7 from '../../../images/partner/7.png';
import PartnerLogo8 from '../../../images/partner/8.png';
import PartnerLogo9 from '../../../images/partner/9.png';
import PartnerLogo10 from '../../../images/partner/10.png';
import PartnerLogo11 from '../../../images/partner/11.png';
import PartnerLogo12 from '../../../images/partner/12.png';
import PartnerLogo13 from '../../../images/partner/sundaram.png';


class LogoCarousel extends Component{
	
	render(){
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 6,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 360,
					settings: {
						slidesToShow: 2,
					}
				}
			]
		};
		
		return(
			<>
				<Slider {...settings} className="testimonial-carousel owl-btn-center-lr owl-btn-1">
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo1} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo2} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo13} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo3} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo4} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo5} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo6} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo4} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo5} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo6} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo7} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo8} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo9} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo10} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo11} alt=""/></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="client-logo">
							<Link to="#"><img src={PartnerLogo12} alt=""/></Link>
						</div>
					</div>
					
					
				</Slider >
			</>
		);
	}
}

export default LogoCarousel;