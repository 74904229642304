import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Images
import caseStudies1 from "../../../images/work-thumb/diet.jpg";
import caseStudies2 from "../../../images/work-thumb/max1.png";
import caseStudies3 from "../../../images/work-thumb/photon.jpg";
import caseStudies4 from "../../../images/work-thumb/nu1.jpg";
import caseStudies5 from "../../../images/work-thumb/ador.jpg";
import caseStudies6 from "../../../images/work-thumb/beveracious1.jpg";
import caseStudies7 from "../../../images/work-thumb/igi.jpg";
import caseStudies8 from "../../../images/work-thumb/ijcpms1.jpg";
import caseStudies9 from "../../../images/work-thumb/kf.jpg";
import caseStudies10 from "../../../images/work-thumb/hotbrews1.png";
import caseStudies11 from "../../../images/work-thumb/sk.jpg";
import caseStudies12 from "../../../images/work-thumb/presume1.png";
import caseStudies14 from "../../../images/work-thumb/advent.jpg";
import caseStudies15 from "../../../images/work-thumb/webook.jpg";

export default class LogoCarousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,

      slidesToShow: 4,
      slideTransition: "linear",
      autoplay: true,
      autoplayTimeout: 2200,
      autoplaySpeed: 2200,
      stagePadding: 500,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    };
    return (
      <div>
        <Slider
          {...settings}
          className="case-studies-carousel p-b20 owl-btn-center-lr owl-btn-1">
          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies1} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/diet"}>Dnyanshree Institute</Link>
                </h4>
                <span className="exe-title">Branding | Web Design</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies2} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/maxcess"}>Maxcess</Link>
                </h4>
                <span className="exe-title">Branding</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies14} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/advent"}>Advent Technologies</Link>
                </h4>
                <span className="exe-title">Web Design | Branding</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies15} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/webook"}>Webook</Link>
                </h4>
                <span className="exe-title">App UI/UX Design</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies5} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/ador"}>Ador Welding Ltd.</Link>
                </h4>
                <span className="exe-title">Branding | Packaging</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies4} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/nutritive"}>Nutritive</Link>
                </h4>
                <span className="exe-title">Branding</span>
              </div>
            </div>
          </div>

          <div className="slider-item ">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies3} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/photon"}>Photon Eye Center</Link>
                </h4>
                <span className="exe-title">Branding | Web Design</span>
              </div>
            </div>
          </div>

          <div className="slider-item ">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies6} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/beveracious"}>Beveracious</Link>
                </h4>
                <span className="exe-title">
                  Brand Identity | Branding | Packaging
                </span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies7} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/igi"}>Indira Group of Institutes</Link>
                </h4>
                <span className="exe-title">Branding | Website | UIUX</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies8} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/ijcpms"}>IJCPMS</Link>
                </h4>
                <span className="exe-title">Branding</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies9} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/karwaan"}>Karwaan Foundation</Link>
                </h4>
                <span className="exe-title">Branding | Website Design</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies10} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/hotbrews"}>Hot Brews</Link>
                </h4>
                <span className="exe-title">Branding</span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies11} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/shilpa-kankani"}>Mrs. Shilpa Kankani</Link>
                </h4>
                <span className="exe-title">
                  Brand Identity | Website | LMS
                </span>
              </div>
            </div>
          </div>

          <div className="slider-item">
            <div className="portfolio-box style-2 m-b0">
              <div className="portfolio-media">
                <img src={caseStudies12} alt="" />
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/presume-trade"}>Presume Trade</Link>
                </h4>
                <span className="exe-title">Branding</span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
